export const state = {
    demandArray: [],
    demandDetail: [],
    demandInsert: [],
    demandDelete: [],
    demandUpdate: [],
    demandVotes: [],
    setDemandVotes: [],
    demandMessageArray: {
        error: '',
        success: '',
        message: '',
        redirect: ''
    }
}