<template>
  <SliderSmall sliderSmallTitle="Guitar Hearts - Impressum" />
  <div class="content mt-5 mb-5">
    <h2>Impressum</h2>
    <h4>Informationspflicht laut § 5 TMG.</h4>

    The Guitar Hearts Project e.V<br>
    Langgasse 17<br>
    56348 Bornich<br>
    Deutschland<br>
    <br>
    Tel.: 06771/4684194<br>
    E-Mail: <a href="mailto:guitarheartsproject@outlook.com">guitarheartsproject@outlook.com</a><br>
    <br>
    <h4>Vereinszweck</h4>
    <br>
    Zweck des Vereins ist die Förderung von Kunst und Kultur durch ideelle und finanzielle Förderung anderer steuerbegünstigter <br>
    Körperschaften und von Körperschaften des öffentlichen Rechts. Der Satzungszweck wird verwirklicht durch das Sammeln und <br>
    gegebenenfalls wieder herrichten von gebrauchten Akustik, E-Gitarren, E-Bässen sowie Gitarren Zubehör und die Weitergabe an andere<br>
    steuerbegünstige Körperschaften und an Körperschaften des öffentlichen Rechts. Ebenfalls durch die Beschaffung von Mitteln durch Beiträge,<br>
    Spenden sowie durch Veranstaltungen, die der ideellen Werbung für den geförderten Zweck dienen.<br>
    <br>
    Gemeinschaftlich vertretungsbefugt<br>
    1. Vorsitzender:<br>
    Lars-P. Nüsse, Langgasse 17, 56348 Bornich, Telefon: +06771 4684194, E-Mail: guitarhearsproject@outlook.com<br>
    <br>
    2. Vorsitzender:<br>
    Melanie Nüsse, Langgasse 17, 56348 Bornich, Telefon: +06771 4684194, E-Mail: guitarhearsproject@outlook.com<br>
    <br>
    Register: Vereinsregister<br>
    Registernummer: HRB 123456<br>
    Registergericht: Amts- und Landgericht Koblenz<br>
    Quelle: Erstellt mit dem Impressum Generator von AdSimple in Kooperation mit testingly.de<br>
    <br>
    <h4>EU-Streitschlichtung</h4>
    <br>
    Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die <br>
    Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br>
    Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission <br>
    unter http://ec.europa.eu/odr?tid=321108968 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.<br>
    <br>
    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren <br>
    vor einer Verbraucherschlichtungsstelle teilzunehmen.<br>
    <br>
    <h4>Haftung für Inhalte dieser Website</h4>
    <br>
    Wir entwickeln die Inhalte
  </div>
  <Footer />
</template>

<script>
import SliderSmall from "@/components/landing-page/SliderSmall";
import Footer from "@/components/Footer";
export default {
  name: "Impressum",
  components: {Footer, SliderSmall}
}
</script>

<style scoped>
.content {
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: left;
}
</style>