export const state = {
    newsArray: [],
    newsDetailArray: [],
    newsUpdateArray: [],
    newsDeleteArray: [],
    newsInsertArray: [],
    newsAttachmentArray: [],
    newsMessageArray: {
        error: '',
        success: '',
        message: '',
        redirect: ''
    }
}