export const state = {
    instrumentsArray: [],
    instrumentInsert: [],
    instrumentDelete: [],
    instrumentUpdate: [],
    instrumentMessageArray: {
        error: '',
        success: '',
        message: '',
        redirect: ''
    }
}