export const state = {
    blogArray: [],
    blogUpdate: [],
    blogInsert: [],
    blogDelete: [],
    blogMessageArray: {
        error: '',
        success: '',
        message: '',
        redirect: ''
    }
}