<template>
  <SliderSmall sliderSmallTitle="Guitar Hearts - Satzung" />
  <div class="content mt-5 mb-5">
    <h2>Satzung</h2>
    <h4>§ 1. Name und Sitz</h4>
    <ol>
      <li>Der Verein führt den Namen „The Guitar Hearts Project e.V.“</li>
      <li>Er soll in das Vereinsregister eingetragen werden und führt danach den Zusatz „e.V.“</li>
      <li>Der Sitz des Vereins ist 56348 Bornich</li>
    </ol>

    <h4>§ 2. Zweck</h4>
    <ol>
      <li>
        Zweck des Vereins ist die Förderung von Kunst und Kultur durch ideelle und finanzielle Förderung anderer steuerbegünstigter Körperschaften und von Körperschaften des öffentlichen Rechts.
        Der Satzungszweck wird verwirklicht durch das Sammeln und gegebenenfalls wieder herrichten von gebrauchten Akustik, E-Gitarren, E-Bässen sowie Gitarren Zubehör und die Weitergabe an andere steuerbegünstige Körperschaften und an Körperschaften des öffentlichen Rechts.
        Ebenfalls durch die Beschaffung von Mitteln durch Beiträge, Spenden sowie durch Veranstaltungen, die der ideellen Werbung für den geförderten Zweck dienen.
      </li>
      <li>
        Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts „Steuerbegünstigte Zwecke“ der Abgabenordnung.
        Der Verein ist selbstlos tätig; er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke.
        Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke verwendet werden.
        Die Mitglieder erhalten keine Zuwendungen aus den Mitteln des Vereins.
        Es darf keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind oder durch unverhältnismäßig hohe Vergütungen begünstigt werden.
      </li>
    </ol>

    <h4>§3. Mitgliedschaft</h4>
    <ol>
      <li>
        Mitglied des Vereins kann jede natürliche Person werden.
      </li>
      <li>
        Über die Aufnahme entscheidet, nach schriftlichem Antrag, der Vorstand.
      </li>
      <li>
        Der Austritt aus dem Verein ist jederzeit zulässig. Er muss schriftlich gegenüber dem Vorstand erklärt werden.
      </li>
      <li>
        Ein Mitglied kann aus dem Verein ausgeschlossen werden, wenn sein Verhalten in grober Weise gegen die Interessen des Vereins verstößt. Über den Ausschluss entscheidet die Mitgliederversammlung.
      </li>
      <li>
        Die Mitgliedschaft endet mit dem Tod des Mitglieds.
      </li>
      <li>
        Das ausgetretene Mitglied hat keinen Anspruch auf das Vereinsvermögen (E/RS 553 (11.06) A G A7
      </li>
      <li>
        Die Mitglieder haben einen Jahresbeitrag von 20 € zu leisten.
      </li>
      <li>
        Die Höhe und Fälligkeit der Mitgliedsbeiträge wird durch die Mitgliedsversammlung festgesetzt.
      </li>
    </ol>

    <h4>§4. Vorstand</h4>
    <ol>
      <li>
        Der Gesamtvorstand des Vereins besteht aus dem
        <ul>
          <li>1.Vorsitzenden, Lars Nüsse</li>
          <li>2.Vorsitzenden, Melanie Nüsse</li>
          <li>1.Beisitzer, Ronja Joachim</li>
        </ul>
      </li>
      <li>
        Der Vorstand im Sinne des §26 BGB besteht aus dem
        <ul>
          <li>
            1.Vorsitzenden
          </li>
          <li>
            2.Vorsitzenden
          </li>
        </ul>
        Sie vertreten den Verein gemeinschaftlich.
      </li>
      <li>
        Der Vorstand wird von der Mitgliederversammlung auf die Dauer von zwei Jahren gewählt. Er bleibt jedoch solange im Amt, bis eine Neuwahl erfolgt ist.
      </li>
    </ol>

    <h4>§5 Mitgliederversammlung</h4>
    <ol>
      <li>
        Die ordentliche Mitgliederversammlung findet einmal jährlich statt. Außerdem muss eine Mitgliederversammlung einberufen werden, wenn das Interesse des Vereins es erfordert oder wenn mindestens 5/10 der Mitglieder die Einberufung schriftlich, unter Angabe des Zwecks und der Gründe, es verlangt.
      </li>
      <li>
        Jede Mitgliederversammlung ist vom Vorstand schriftlich oder elektronisch unter Einhaltung der Einladungsfrist von 4 Wochen und unter Angabe der Tagesordnung einzuberufen.
      </li>
      <li>
        Versammlungsleiter ist der 1. Vorsitzende und im Falle seiner Verhinderung der 2.Vorsitzende.
        Sollten beide nicht Anwesend sein, wird der Versammlungsleiter von der Mitgliederversammlung gewählt.
        Soweit der Schriftführer nicht anwesend ist, wird auch dieser von der Mitgliederversammlung gewählt.
      </li>
      <li>
        Jede ordnungsgemäß einberufene Mitgliederversammlung ist ohne Rücksicht auf die Zahl der erschienenen Mitglieder beschlussfähig.
      </li>
      <li>
        Die Beschlüsse der Mitgliederversammlung werden mit einfacher Mehrheit der abgegebenen gültigen Stimmen erfasst.
        Zur Änderung der Satzung und des Vereinszwecks ist jedoch eine Mehrheit von 3/4 der abgegebenen Stimmen erforderlich.
      </li>
      <li>
        Über die Beschlüsse der Mitgliederversammlung ist ein Protokoll aufzunehmen, das vom Versammlungsleiter und vom Schriftführer zu unterschreiben ist.
      </li>
    </ol>

    <h4>§6 Auflösung, Anfall des Vereinsvermögens</h4>
    <ol>
      <li>
        Zur Auflösung des Vereins ist eine Mehrheit von 4/5 der abgegebenen gültigen Stimmen erforderlich.
      </li>
      <li>
        Bei Auflösung des Vereins oder bei Wegfall steuerbegünstigter Zwecke fällt das Vermögen des Vereins an das Projekt „Kinder zu Tisch“, Deutscher Kinderschutzbund, Kreisverband Koblenz e.V., der es unmittelbar und ausschließlich für gemeinnützige, mildtätige oder kirchliche Zwecke zu verwenden hat.
      </li>
    </ol>

    Bornich, den 26.02.2016

  </div>
  <Footer />
</template>

<script>
import SliderSmall from "@/components/landing-page/SliderSmall";
import Footer from "@/components/Footer";
export default {
  name: "Satzung",
  components: {Footer, SliderSmall}
}
</script>

<style scoped>
.content {
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: left;
}
</style>